import { Link } from "@StarberryUtils";
import * as React from "react"
import { Button, Container, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import dateFormat from 'dateformat';
import { navigate } from "gatsby"

// images
import ImgPerson from "../../../images/person.jpg"
import blogImg1 from "../../../images/home/blog-img-01.webp"
import blogImg2 from "../../../images/home/blog-img-02.webp"
import blogImg3 from "../../../images/home/blog-img-03.webp"
import ContactCard from "../../ContactCard/ContactCard"
// Style
import './LatestBlogs.scss';
import ScrollAnimation from 'react-animate-on-scroll';

import { LatestBlogs, OtherInsights } from "../../../queries/common_use_query"
import { removeDataAttributes } from "../../../comQueryStructure";


const LatestBlogsComponent = (props) => {

  const { loading, error, data: data } = props.Filter_by_Tag !==null?OtherInsights(props.Filter_by_Tag):LatestBlogs(); 
  const latestBlogsData = removeDataAttributes(data?.insights)

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst:true,
    centerMode: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint:1199,
         settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
            }
    },
      {
          breakpoint: 767,
           settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
              }
      },
      {
        breakpoint:99999,
         settings: {
                autoplay: true,
                slidesToShow: 2,
                infinite: false,
                centerMode: false,
                variableWidth: true,
            }
    },
  ]
}

const openSharefrndformModal = (e) => { 
  navigate('/about/case-studies-and-insights/', { state: { slug: e} });
}

 const result = latestBlogsData?.filter(item => item.Tag === props.Filter_by_Tag);
 const peopleInsightList = latestBlogsData?.filter(item => item.Author === "Ramy Wali").slice(0,10)
 var peopleInsightTitle = "Recent Articles Written by Ramy Wali"
 var peopleInsightContent = "Discover the most recent articles authored by Ramy Wali, covering a wide range of subjects with thoroughness and lucidity. Whether it's emerging tech or cultural matters, Ramy's pieces provide new angles and insightful examinations. Keep abreast of the conversation with Ramy Wali's latest offerings."
 let datatoshow = props.isCommunity ? result : props.isPeoplePage ? peopleInsightList : latestBlogsData?.slice(0,8)

  return (
    <div className="latest-blogs text-center text-xl-left">
      <Container>
        <Row>
       
           <Col xl={4}>
               <strong className="sub-title">Blogs and articles</strong>
               <h2 className="main-title">Insights from our expert team.</h2>
               {props?.Content && 
               <p className="main-desc d-md-block">{props?.Content}</p>
               }
                <a href="javascript:void(0)" onClick={() => openSharefrndformModal(props.Filter_by_Tag)} className="btn btn-primary d-none d-xl-inline-block"><span>View More</span></a>            
           </Col>         
          <Col xl={8}>
            <Slider {...settings} >
              {
                datatoshow?.map((item, index) => {
                  var date_of_item = item.Date && dateFormat(item.Date, "dd mmmm yyyy");
                  var split_tags = item.Tag ? item.Tag.split(', ') : '';

                  return (
                    //  <div className="animated">
                      <ContactCard
                        cardImg={item.Tile_Image}
                        cardTitle={item.Title}
                        date={date_of_item}
                        cardTag={item.Tag ? split_tags[0] : item.Category.replace('_', ' ')}
                        link_url={item.URL}
                        imagename={"insight.Tile_Image.tile_image"}
                        article_id={item.id} 
                        imagetransforms={item.ggfx_results}
                        externalLink={item.External_Link}
                        section="latest_blogs"
                      />
                    //  </div>
                  )
                })
              }
            </Slider>
            <a href="javascript:void(0)" onClick={() => openSharefrndformModal(props.Filter_by_Tag)} className="btn btn-primary d-xl-none"><span>View More</span></a>
          </Col>
          {/*  </div> */}
        </Row>
      </Container>


    </div>
  )
}



export default LatestBlogsComponent