import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet";
import { Button, Navbar, Nav, Form, Container, FormControl, Row, Col } from 'react-bootstrap';
import Select from 'react-select';

import { isMobile } from "react-device-detect";
import { Link } from "@StarberryUtils";
import { useLocation } from "@reach/router"

import { priceminRangeSale, pricemaxRangeSale } from "../../components/common/utils";

import NoResultList from '../../components/SearchResult/ResultList/NoResultList';

import $ from "jquery"
import qs from "qs"
import algoliasearch from "algoliasearch/lite"
import {
  connectStats,
  SortBy,
  connectInfiniteHits,
  InstantSearch,
  connectRange,
  Configure,
  SearchBox 
} from "react-instantsearch-dom"
import Cookies from 'universal-cookie'
import { navigate } from "@reach/router"
import "../../pages/properties/assets/styles/_index.scss"

import { ShowProcessedImage } from '../../components/common/ggfx-client/module/components/show-image';
import imageConfig from "../../../static/images/config.json"
import noImage from "../../images/no-image.jpg"

const mycookies = new Cookies();
const updateAfter = 500

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""
var minbedroomval = minbedroomval || ""
var maxbedroomval = maxbedroomval || ""
var minsqftval = minsqftval || ""
var maxsqftval = maxsqftval || ""

const searchClient = algoliasearch(
  `${process.env.GATSBY_ALGOLIA_APP_ID}`,
  `${process.env.GATSBY_ALGOLIA_SEARCH_KEY}`
)
const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`

// Capitalize
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
// Capitalize

// Property results ad block
var ad_slot = 0;
// Property results ad block

// Property results loop
const InfiniteHits = ({
  hits,
  hasPrevious,
  refinePrevious,
  hasMore,
  refineNext,
  location,
}) => {

  const { pathname } = useLocation()

  return (
    <>
      <>
        {(hits.length > 0 && hasPrevious) && (
          <button
            id="myprevbut"
            onClick={refinePrevious}
            disabled={!hasPrevious}
            className="d-none"
          >
            Show prev
          </button>
        )}
      </>

      {
        hits.map((hit, i) => {

          // property details url structure
          let uriStr = "properties-for-sale/";

          if (hit.search_type === "lettings") {
            uriStr = `properties-for-rent/`
            if (hit.status === "rented") {
              uriStr = `properties-let/`
            }
          }else{
            if (hit.status === "sold") {
              uriStr = `properties-sold/`
            }
          } 
          // property details url structure

          // Ad block
          ad_slot = i;
          // Ad block

          return (
            <>
              <Col md={6} xl={4} key={i} id={`mySaleProp${hit.objectID}`} className={` ${hit.status}`}>
                <div className="animated">
                  <div className="contact-card">
                    <figure>
                      <Link id={`mySaleProp${hit.objectID}`}
                        onClick={() => mycookies.set('mySaleProp', hit.objectID, { path: '/', maxAge: 300 })}
                        to={`/${uriStr}${hit.slug}-${hit.objectID}`}
                        state={{ referrer: pathname }}>
                        <span className="overlay_bg"></span>
                        {
                          hit.images && (hit.images.length > 0) ? (
                            <ShowProcessedImage images={hit.images[0]} attr={{ className: 'propery-img', alt: '' }} transforms={imageConfig.property.searchResults.sizes} />
                          ) : (
                            <img src={noImage} alt="" />
                          )
                        }
                      </Link>

                      {hit.status === "sold" && <span className="card-tag d-block">SOLD</span>}
                      {hit.status === "rented" && <span className="card-tag d-block">LET</span> }
                    </figure>

                    <Link id={`mySaleProp${hit.objectID}`}
                      onClick={() => mycookies.set('mySaleProp', hit.objectID, { path: '/', maxAge: 300 })}
                      to={`/${uriStr}${hit.slug}-${hit.objectID}`}
                      state={{ referrer: pathname }}>
                      <div className="info">
                        <h6 className="card-title">{hit.title}</h6>
                        <span className="date">{hit.price.toLocaleString() + " AED"}</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </Col>
            </>
          )
        })
      }

     
          <Col lg={12} className="mb-5 text-center">
            {(hits.length > 0 && hasMore) && (
              <button
                id="mynextbut"
                onClick={refineNext}
                disabled={!hasMore}
                className="btn btn-primary"
              >
                <span>Load more</span>
              </button>
            )}
          </Col>
       
    </>
  )
}

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)
// Property results loop


// Property No Results
const NoStats = ({ processingTimeMS, nbHits }) => {
  return (
    <>
      {nbHits === 0 &&
        <Container>
          <Row>
            <Col lg={12} className="text-center no-results-section">
              <div>Unfortunately, we do not currently have any properties that match your search criteria.</div>

              <NoResultList uriStr={'properties-for-sale'} status={'For Sale'} />
            </Col>
          </Row>
        </Container>
      }
    </>
  )
}

const CustomNoStats = connectStats(NoStats)
// Property No Results

// Property Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>


// Property Min Price
const priceminRange = priceminRangeSale

const minpriceSelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let minPriceRange = priceminRange;
  if (currentRefinement.max !== undefined && currentRefinement.max != "0") {
    minPriceRange = priceminRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
  }

  return (
    <Form.Group className="price-filter">
      <div className="select-group">
        <Select
          name="price"
          options={minPriceRange}
          defaultValue={currentRefinement.min || "0"}
          classNamePrefix={"select-opt"}
          placeholder={currentRefinement.min === undefined || null || 0 ? "Min Price" : currentRefinement.min.toLocaleString() + " AED"}
          onChange={(e) => {
            minpriceval = e.value
            if (e.value === "0") {
              if (maxpriceval) {
                refine({
                  max: maxpriceval,
                })
              } else refine(e.value, e.label)
            } else {
              if(maxpriceval > 0){
                refine({
                  min: e.value,
                  max: maxpriceval,
                })
              }else{
                refine({
                  min: e.value 
                })
              }
             
            }
          }}
          isSearchable={false}
        />
      </div>
    </Form.Group>
  )
}

const CustomminpriceSelect = connectRange(minpriceSelect)
// Property Min Price

// Property Max Price
const pricemaxRange = pricemaxRangeSale

const maxpriceSelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {

  let maxPriceRange = pricemaxRange;
  let maxpricedisable = ''
  if (currentRefinement.min !== undefined) {
    maxPriceRange = pricemaxRange.filter((x) => x.value == "0" || parseInt(x.value) > parseInt(currentRefinement.min))
  }
  if (currentRefinement.min == "10000000") {
    maxpricedisable = "disabled"
  }

  return (
    <Form.Group className="price-filter">
      <div className="select-group">
        <Select
          name="price"
          options={maxPriceRange}
          defaultValue={currentRefinement.max || "0"}
          classNamePrefix={"select-opt"}
          placeholder={currentRefinement.max === undefined || null || 0 ? "Max Price" : currentRefinement.max.toLocaleString() + " AED"}
          onChange={(e) => {
            maxpriceval = e.value
            if (e.value === "0") {
              if (minpriceval) {
                refine({
                  min: minpriceval,
                })
              } else refine(e.value, e.label)
            } else {
              refine({
                min: minpriceval,
                max: e.value,
              })
            }
          }}
          isSearchable={false}
          // menuIsOpen={true}
        />
      </div>
    </Form.Group>
  )
}

const CustommaxpriceSelect = connectRange(maxpriceSelect)
// Property Max Price


// Property Min Bedroom
const bedroomminRange = [
  {
    value: "",
    label: "Bedrooms"
  },
  {
    value: "0",
    label: "Studio"
  },
  {
    value: "1",
    label: "1 +"
  },
  {
    value: "2",
    label: "2 +"
  },
  {
    value: "3",
    label: "3 +"
  },
  {
    value: "4",
    label: "4 +"
  },
  {
    value: "5",
    label: "5 +"
  },
]

const minbedroomSelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let minBedroomRange = bedroomminRange;
  if (currentRefinement.max !== undefined && currentRefinement.max != "0") {
    minBedroomRange = bedroomminRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
  }

  return (
    <Form.Group className="bed-filter">
      <div className="select-group">
        <Select
          name="bedroom"
          options={minBedroomRange}
          defaultValue={currentRefinement.min || ""}
          classNamePrefix={"select-opt"}
          placeholder={
            currentRefinement.min === undefined || null ? "Bedrooms"
              : currentRefinement.min === 0 ? "Studio"
                : currentRefinement.min + " +"
          }
          onChange={event => {
            if (event.value === "") {
              refine(event.value)
            } else if (event.value === "0") {
              refine({
                max: event.value,
              })
            } else {
              refine({
                min: event.value,
              })
            }
          }}

          isSearchable={false}
        />
      </div>
    </Form.Group>
  )
}


const departmentArr = [
  { value: "Residential", label: "Residential" },
  { value: "Commercial", label: "Commercial" },
  { value: "Off plan", label: "Off plan" }
]

const departmentSelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
 
  return (
    <Form.Group className="bed-filter">
      <div className="select-group">
        <Select
          name="department"
          options={departmentArr}
          defaultValue={"Residential"}
          classNamePrefix={"select-opt"}
          placeholder={"Department"}
         //   currentRefinement.min === undefined || null ? "Bedrooms"
          //    : currentRefinement.min === 0 ? "Studio"
          //      : currentRefinement.min + " +"
          //}
          onChange={event => {
            if (event.value === "") {
              refine(event.value)
            } else if (event.value === "0") {
              refine({
                max: event.value,
              })
            } else {
              refine({
                min: event.value,
              })
            }
          }}
          isSearchable={false}
        />
      </div>
    </Form.Group>
  )
}

const CustomdepartmentSelect = connectRange(departmentSelect)


const CustomminbedroomSelect = connectRange(minbedroomSelect)
// Property Min Bedroom

// Create URL
export const createURL = (props, state) => {

  //console.log("createURL", state);

  let myarea = state.menu

  const isDefaultRoute =
    !state.query &&
    !state.SortBy &&
    state.page === 1 &&
    state.menu &&
    state.range &&
    !state.range.price &&
    !state.range.size &&
    !state.range.bedroom &&
    (state.refinementList && !state.refinementList.accommodation_summary && !state.refinementList.accommodation_summary.length === 0)


  if (isDefaultRoute) {
    return ""
  }


  var areaPath = "in-dubai/"
  var bedroomPath = ""
  var pricePath = ""
  var pagePath = ""
  var propertypath = ""
  var sortPath = ""
  var amenitiesPath = ""
  var saleTypePath = ""
  var furnishPath = ""
  var sizePath = ""

  if (state.query) {
    if (state.query) {
      areaPath = "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
    }
  }

  const queryParameters = {}

  //console.log("propertypath 1",  state);

  // Property path
  if (state.menu) {
    if (myarea["seobuilding"]) {
      propertypath = "type-" + myarea["seobuilding"] + "/"
    }

    // Sale type path
    if (myarea["sublisting_type"]) {
      saleTypePath = "sale-type-" + myarea["sublisting_type"] + "/"
    }
    // Sale type path

    // furnish path
    if (myarea["furnished"]) {
      furnishPath = "furnished-" + myarea["furnished"] + "/"
    }
    // furnish path
  }
  // Property path

  //console.log("propertypath 2",  state.menu);

  // Bedrooms & Price range path
  if (state.range) {
    // Bedrooms
    if (state.range.hasOwnProperty('bedroom') && state.range.bedroom.max === 0) {
      bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
    }
    if (state.range.bedroom) {
      if (state.range.bedroom.min) {
        if (state.range.bedroom.min === "0") {
          bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
        } else {
          bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
        }
      }
    }

    // Price
    if (state.range.price) {
      if (state.range.price.min && state.range.price.max) {
        pricePath = "between-" + state.range.price.min + "-and-" + state.range.price.max + '/' //queryParameters.price_min = state.range.price.min;
      } else if (state.range.price.max) {
        pricePath = "under-" + state.range.price.max + '/' //queryParameters.price_max = state.range.price.max;
      } else if (state.range.price.min) {
        pricePath = "over-" + state.range.price.min + '/' //queryParameters.price_max = state.range.price.max;
      }
    }

    // Size
    if (state.range.size) {
      if (state.range.size.min && state.range.size.max) {
        sizePath = "size-" + state.range.size.min + "-to-" + state.range.size.max + '/' //queryParameters.price_min = state.range.price.min;
      } else if (state.range.size.max) {
        sizePath = "below-" + state.range.size.max + '/' //queryParameters.price_max = state.range.price.max;
      } else if (state.range.size.min && parseInt(state.range.size.min) > 0) {
        sizePath = "above-" + state.range.size.min + '/' //queryParameters.price_max = state.range.price.max;
      }
    }
  }
  // Bedrooms & Price range path

  // Sort path
  if (state.sortBy) {
    if (state.sortBy === index_name + "_price_asc") {
      sortPath = "sortby-price-asc/";
    }
    if (state.sortBy === index_name + "_price_desc") {
      sortPath = "sortby-price-desc/";
    }
    if (state.sortBy === index_name + "_most_recent") {
      sortPath = "sortby-most-recent/";
    }
  }
  // Sort path

  // Page path
  if (state.page && state.page !== 1) {
    pagePath = `page-${state.page}/`
  }
  // Page path

  // Amenities path
  if (state.refinementList) {
    //console.log("accommodation_summary==>", typeof state.refinementList.accommodation_summary, state.refinementList.accommodation_summary);

    if (typeof state.refinementList.accommodation_summary === "object") {
      if (state.refinementList.accommodation_summary.length > 0) {
        var accommodation_summary_list = state.refinementList.accommodation_summary.filter(function (item, index, inputArray) {
          return inputArray.indexOf(item) == index;
        });
        if (accommodation_summary_list.length > 0) {
          amenitiesPath = `with-${accommodation_summary_list.map(encodeURIComponent).join('-and-')}/`
        }
      }
    } else {
      amenitiesPath = `with-${state.refinementList.accommodation_summary}/`
    }

  }
  // Amenities path

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  })

  let myUrl = `${areaPath}${saleTypePath}${propertypath}${bedroomPath}${pricePath}${amenitiesPath}${sizePath}${furnishPath}${queryString}${pagePath}${sortPath}`

  //console.log("myUrl", myUrl);

  if ("/" === myUrl.substr(myUrl.length - 1))
    myUrl = myUrl.substr(0, myUrl.length - 1)

  if (!myUrl.endsWith('/'))
    myUrl = myUrl + '/';

  myUrl = myUrl.toLowerCase();

  return `/properties/for-sale/${myUrl}`
}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState) =>
  searchState ? `${createURL(props, searchState)}` : ""

const urlToSearchState = location => {
  if (location) {
    var query = ""
    let pathUri_main = location.pathname.split("/properties/for-sale")

    var areaVal = "downtown"
    var bedVal = 21
    var minpriceVal = ""
    var maxpriceVal = ""
    var minbedroomval = ""
    var maxbedroomval = ""
    var pageVal = ""
    var propertyval = ""
    var sortVal = ""
    var sortVal_filt = ""
    var amenitiesVal = ""
    var saleTypeVal = ""
    var minsizeval = ""
    var maxsizeval = ""
    var furnishVal = ""

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")

      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }

        // Sale type
        if (pathUri[vi].indexOf("sale-type-") == 0) {
          saleTypeVal = pathUri[vi].replace("sale-type-", "")
        }

        // Property type
        if (pathUri[vi].indexOf("type-") == 0) {
          propertyval = pathUri[vi].replace("type-", "")
        }

        // Area
        if (pathUri[vi].indexOf("in-") == 0) {
          areaVal = pathUri[vi].replace("in-", "")
        }

        // Price
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("over-") >= 0 ||
          pathUri[vi].indexOf("under-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].split("over-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("under-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        // furnished type
        if (pathUri[vi].indexOf("furnished-") == 0) {
          furnishVal = pathUri[vi].replace("furnished-", "")
        }

        // Size
        if (
          pathUri[vi].indexOf("size-") >= 0 ||
          pathUri[vi].indexOf("above-") >= 0 ||
          pathUri[vi].indexOf("below-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].split("above-")
          if (priceFilt1[1]) {
            minsizeval = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("below-")
          if (priceFilt2[1]) {
            maxsizeval = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("size-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-to-")
            minsizeval = priceFilt4[0]
            maxsizeval = priceFilt4[1]
          }
        }

        // Bedrooms
        if (pathUri[vi].indexOf("-and-more-") > 0) {
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
          sortVal = index_name + "_bedrooms"
        }

        if (pathUri[vi].indexOf("studio") === 0) {
          // its studio bedroom
          bedVal = 0
        }

        // Sort by
        if (pathUri[vi].indexOf("sortby-") >= 0) {
          sortVal_filt = pathUri[vi].replace("sortby-", "")

          //sortVal = index_name;

          if (sortVal_filt === "price-asc") {
            sortVal = index_name + "_price_asc"
          }

          if (sortVal_filt === "price-desc") {
            sortVal = index_name + "_price_desc"
          }

          if (sortVal_filt === "most-recent") {
            sortVal = index_name + "_most_recent"
          }
        }

        // Amenities
        if (pathUri[vi].indexOf("with-") >= 0) {
          // its Amenities
          var amenitiesArrfilt = pathUri[vi].replace("with-", "")
          amenitiesVal = amenitiesArrfilt.split("-and-")
        }

        // Page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }

  // Sale type
  if (saleTypeVal) {

    if ('primary-market' == saleTypeVal) {
      saleTypeVal = "Primary Market"
    }
    if ('off-plan' == saleTypeVal) {
      saleTypeVal = "Off-Plan"
    }
    if ('lease-to-own' == saleTypeVal) {
      saleTypeVal = "Lease to Own"
    }

    query += `&menu[sublisting_type]=` + saleTypeVal
  }

  // Furnished type
  if (furnishVal) {
    query += `&menu[furnished]=` + furnishVal
  }

  // Size
  if (minsizeval) {
    query += `&range[size][min]=` + minsizeval
  }
  if (maxsizeval) {
    query += `&range[size][max]=` + maxsizeval
  }

  // Property type
  if (propertyval) {
    query += `&menu[seobuilding]=` + propertyval
  }

  // Area
  if (areaVal) {
    areaVal = areaVal.toLowerCase();
    if (areaVal == "dubai") query += `&query=`
    else query += `&query=` + areaVal
  }

  // Price
  if (minpriceVal) {
    query += `&range[price][min]=` + minpriceVal
  }

  if (maxpriceVal) {
    query += `&range[price][max]=` + maxpriceVal
  }

  if (bedVal === 0) {
    query += `&range[bedroom][max]=` + 0
    query += `&range[bedroom][min]=` + 0
  } else if (bedVal < 21) {
    query += `&range[bedroom][min]=` + bedVal
  }

  // Amenities
  if (amenitiesVal) {
    for (let v = 0; v <= amenitiesVal.length; v++) {
      if (typeof amenitiesVal[v] !== "undefined") {
        query += `&refinementList[accommodation_summary]=` + amenitiesVal[v]
      }
    }
  }

  // Page
  if (pageVal) {
    query += `&page=${pageVal}`
  }

  // Sort by
  if (sortVal) {
    query += `&sortBy=` + sortVal
  }

  return qs.parse(query)
}
// URL to searchstate

class PropertySales extends React.Component {

  constructor(props) {
    super(props);
  }

  // 
  state = {
    searchState: urlToSearchState(this.props.location),
    refinementList: {
      accommodation_summary: []
    },
    menu: {
      furnished: ""
    },
    range: {
      size: {
        min: "",
        max: ""
      }
    },
    include_sold: false,
    property_type: "For Sale",
    department: "residential"

  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ searchState: urlToSearchState(this.props.location) })
    }
  }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState)
    this.debouncedSetState = setTimeout(() => {
      // navigate(searchStateToUrl(this.props, searchState), searchState)
    }, updateAfter)
    this.setState({ searchState })

  }

  componentDidMount() {
    // Filter active onscroll
    window.addEventListener("scroll", this.handleScroll, true)
    // Filter active onscroll

    // load on top
    $(document).ready(function () {
      $("html, body").scrollTop(0);
    });
    // load on top
  }

  // Filter active onscroll
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  navigateSearchUrl = () => {
    //console.log("propertypath state 3", this.state)
    var state_update = this.state.searchState;
    navigate(createURL(this.props, state_update))
  }
  // Amenities filter


  // Sales & Rent dropdown
  onChangeSellRent = (e) => {
    navigate(`/${e.value}`)
  }
  // Sales & Rent dropdown

  // Mobile filter results
  mobileFilter = (e) => {
    $(".filter-results-dropdown").toggleClass("active-result")
  }
  // Mobile filter results

  // Clear filter
  clearFilter = (e) => {
    navigate(`/properties/for-sale/in-dubai/`)
  }
  // Clear filter


  resetFilter = (e) => {
    $(".filter-search-overlay").removeClass("active-filter")
    $("html").removeClass("active-filter")
    navigate(`/properties/for-sale/in-dubai/`)
  }
  // Advanced filter

  render() {
    // console.log("paramssss",searchParams)

    let areaPropertyName;

    var mystatus = "";

    if (this.state.include_sold) {
      mystatus = `(status: "${this.state.property_type}" OR status:"sold")`
    }else if (this.state.property_type == "for rent") {
      mystatus = `(status: "${this.state.property_type}" OR status:"rented")`
    } else {
      mystatus = `status: "${this.state.property_type}"`
    }


    return (
      <>
        <div id="properties-section" className="properties-section  community-properties-section">
          <InstantSearch
            indexName={index_name}
            searchClient={searchClient}
            searchState={this.state.searchState}
            onSearchStateChange={this.onSearchStateChange}
            
            // createURL={createURL}
            // routing="true"
          >
            <Configure
              hitsPerPage={12}
              filters={`publish:true AND department:${this.state.department} AND ${mystatus}`}
              query={this.props.Name}
            />

            <div className="result-filter">
              <Container>
                <Row>
                  <div className="col-xl-12">
                  <h2 className="result-title">Search {this.props.Name}, Qatar</h2>
                  <p className="search_subtext">
                    Explore all of our properties for sale in {this.props.Name}, Qatar.
                  </p>                 
                <div className={"h6 d-flex d-xl-none justify-content-between align-items-center filter-results-dropdown"} onClick={this.mobileFilter}><span>Filter Results</span> <i className="arrow-icon"></i></div>
                <Form className="result-search d-xl-flex">

                <Form.Group className="type-filter">
                    <div className="select-group">
                      <Select
                        options={[
                          { value: 'residential', label: 'Residential' },
                          { value: 'commercial', label: 'Commercial' }
                        ]}
                        defaultValue={{ value: 'Residential', label: 'Residential' }}
                        classNamePrefix={"select-opt"}
                        placeholder="Department"
                        onChange={(e)=>{
                          this.setState({department: e.value})
                        }}
                        isSearchable={false}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="type-filter">
                    <div className="select-group">
                      <Select
                        options={[
                          { value: 'for sale', label: 'For Sale' },
                          { value: 'Sold', label: 'Sold' },
                          { value: 'for rent', label: 'For Rent' },
                          { value: 'rented', label: 'Let' }
                        ]}
                        defaultValue={{ value: 'sales', label: 'For Sale' }}
                        classNamePrefix={"select-opt"}
                        placeholder="Property Status"
                        onChange={(e)=>{
                          this.setState({property_type: e.value})
                        }}
                        isSearchable={false}
                      />
                    </div>
                  </Form.Group>

                  {/*<CustomdepartmentSelect attribute="department" />*/}

                  <CustomminpriceSelect attribute="price" />

                  <CustommaxpriceSelect attribute="price" />

                  <CustomminbedroomSelect attribute="bedroom" />

                  <Form.Group className="sort-filter">
                    <div className="pt-2">
                      <i className="sort-icon"></i>
                      <span className="">
                        <div className="d-flex align-items-center">
                          <SortBy
                            defaultRefinement={index_name}
                            className="search-sory-by"
                            items={[
                              { value: index_name, label: "Newly Listed" },
                              { value: index_name + "_price_desc", label: "Highest Price" },
                              { value: index_name + "_price_asc", label: "Lowest Price" },
                            ]}
                          />
                        </div>
                      </span>
                    </div>
                  </Form.Group>
                </Form>
                </div>
                </Row>
              </Container>
            </div>

            <div className="result-list pt-5 pb-0 result-grid">
              <Container>
                <div className="result-cards">
                  <Row>
                    <CustomInfiniteHits location={this.props.location} />
                    <CustomNoStats />
                  </Row>
                </div>
              </Container>
            </div>
          </InstantSearch>
        </div>
      </>
    )
  }
}

export default PropertySales